import React, { useState, useRef } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import {
  Root,
  Container,
  Title,
  Wrapper,
  Form,
  StampImg,
  InputGroup,
  Input,
  Textarea,
  Button,
  Info,
  InfoTitle,
  InfoText,
  Status,
  InputContainer,
  InputLabel,
} from './GetInTouch.style';

import StampSrc from '../../images/stamp-beige.svg';

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const GetInTouch = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formValues, setFormValues] = useState({});
  const [formStatus, setFormStatus] = useState('');
  const [errors, setErrors] = useState({});
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: undefined }));
    setFormStatus('');
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if ([undefined, ''].includes(formValues?.name)) {
      setErrors({ name: 'Invalid name.' });
      setFormStatus('Invalid name.');
      nameRef.current.focus();
      return;
    } else if (!emailPattern.test(formValues?.from || '')) {
      setErrors({ from: 'Invalid Email.' });
      setFormStatus('Invalid Email.');
      emailRef.current.focus();
      return;
    } else if ([undefined, ''].includes(formValues?.message)) {
      setErrors({ message: 'Invalid message.' });
      setFormStatus('Invalid message.');
      messageRef.current.focus();
      return;
    }

    if (!executeRecaptcha) {
      setFormStatus('Invalid reCAPTCHA. Please try again.');
      return;
    }
    const captchaToken = await executeRecaptcha('contact');

    try {
      setFormStatus('Submitting...');
      const response = await fetch(
        `https://${process.env.GATSBY_API_DOMAIN}/contact`,
        {
          method: 'POST',
          body: JSON.stringify({ ...formValues, captchaToken }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      // console.info({ response });
      setFormValues({});
      setErrors({});
      setFormStatus('Contact form successfully submitted!');
    } catch (err) {
      const errorJson = err.responseJSON;
      if (
        ['INVALID_CAPTCHA', 'INVALID_CAPTCHA_SCORE'].includes(errorJson.type)
      ) {
        setFormStatus('Invalid captcha. Please try again.');
      } else {
        setFormStatus('An error occurred. Please try again.');
      }
    }
  };

  const handleFocus = () => {
    const recaptchaEl = document.getElementsByClassName('grecaptcha-badge');
    recaptchaEl[0].style.visibility = 'visible';
  };

  return (
    <Root>
      <Container>
        <Title>Get in touch</Title>
        <Wrapper>
          <Form onSubmit={handleSubmit}>
            <StampImg src={StampSrc} alt="" />
            <Status id="status" role="status">
              {formStatus}
            </Status>
            <InputGroup>
              <InputContainer>
                <InputLabel htmlFor="name-input">Name*</InputLabel>
                <Input
                  ref={nameRef}
                  id="name-input"
                  type="text"
                  name="name"
                  autoComplete="name"
                  placeholder="Name"
                  value={formValues.name || ''}
                  aria-required="true"
                  onChange={handleChange}
                  onFocus={handleFocus}
                  aria-invalid={!!errors.name}
                  aria-describedby={errors.name ? 'status' : undefined}
               
                />
              </InputContainer>
              <InputContainer>
                <InputLabel htmlFor="email-input">Email Address*</InputLabel>
                <Input
                  ref={emailRef}
                  id="email-input"
                  type="text"
                  name="from"
                  autoComplete="email"
                  placeholder="Email Address"
                  value={formValues.from || ''}
                  aria-required="true"
                  onChange={handleChange}
                  onFocus={handleFocus}
                  aria-invalid={!!errors.from}
                  aria-describedby={errors.from ? 'status' : undefined}
                
                />
              </InputContainer>
            </InputGroup>
            <InputContainer>
              <InputLabel htmlFor="message-input">Message*</InputLabel>
              <Textarea
                ref={messageRef}
                id="message-input"
                name="message"
                rows="10"
                placeholder="Message"
                value={formValues.message || ''}
                aria-required="true"
                onChange={handleChange}
                onFocus={handleFocus}
                aria-invalid={!!errors.message}
                aria-describedby={errors.message ? 'status' : undefined}
              ></Textarea>
            </InputContainer>
            <Button type="submit">Submit</Button>
          </Form>

          <Info>
            <InfoTitle>Customer Service Line</InfoTitle>
            <InfoText>
            <a href="tel:865-436-6995">865-436-6995</a>
              <br />
              MON - FRI: 12PM - 8PM
              <br />
              *hours may vary depending on federal holiday
              <br />
              <a href="mailto:customerservice@osdistillery.com">customerservice@osdistillery.com</a>
            </InfoText>

            <InfoTitle>Ole Smoky Distillery</InfoTitle>
            <InfoText>
              903 Parkway, Unit 128
              <br />
              Gatlinburg, TN 37738
              <br />
              MON - THURS: 10AM - 10PM
              <br />
              FRI - SUN: 10AM - 11PM
            </InfoText>
          </Info>
        </Wrapper>
      </Container>
    </Root>
  );
};

export default GetInTouch;
