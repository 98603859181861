import React from 'react';

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import Footer from '../components/Footer/Footer';

import BgDesktopSrc from '../images/hero-fire-bg-desktop.jpg';
import BgMobileSrc from '../images/hero-fire-bg-mobile.jpg';
import SEO from '../components/SEO/SEO';
import CookieDisclaimer from '../components/CookieDisclaimer/CookieDisclaimer';

const GetInTouchPage = () => {
  return (
    <>
      <SEO pageName="contact" />
      <div
        style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
      >
        <Header />
        <main id="main" tabIndex="-1">
          <Hero
            img={{ mobile: BgMobileSrc, desktop: BgDesktopSrc }}
            title="get&nbsp;in&nbsp;touch"
          />
          <GetInTouch />
        </main>
        <Footer />
      </div>
      <CookieDisclaimer />
    </>
  );
};

export default GetInTouchPage;
